<template>
    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>

        <b-row class="match-height">
            <b-col lg="12" md="12">
        
                <b-card title="Add Location">

                    <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                        <div class="alert-body">
                            {{error_message}}
                        </div>
                    </b-alert>
                
                    <b-form @submit.prevent="formSubmit">
                        <b-row>
                            <b-col md="12">
                                <b-row>
                                    <b-col md="12">
                                        <b-form-group label="Project Site" class="required">
                                            
                                            <v-select
                                                v-model="form.site"
                                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                                multiple
                                                label="site_name"
                                                :options="sites"
                                                placeholder="Select"
                                                :close-on-select=false
                            :clear-on-select=false
                                            >
                                              <template #selected-option="{ site_name }">
                                                <span v-if="site_name.length < 23">{{site_name}}</span>
                                                <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
                                              </template>
                                            </v-select>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                

                                <!-- <b-row>
                                            
                                    <b-col md="12">

                                        <b-form-group label="Location Name" class="required">
                                            <b-form-input placeholder="3 to 100 characters" v-model="form.name" />
                                        </b-form-group>
                                    </b-col>
                                </b-row> -->
                                <b-row>
                                    
                                    <b-col md="10">Location Name<span class="text-danger">*</span></b-col>
                                    <b-col md="2"></b-col>
                                </b-row>

                                <b-col v-for="(fm, index) in form.name" :key="index" class="mt-1 desktop_display_flex desktop_pdng_lft_rgt_zero mobile_pdng_lft_rgt_zero mobile_dispaly_inline">
                                    <b-col md="11" class="mobile_pdng_lft_rgt_zero mobile_md_eleven desktop_pdng_lft_rgt_zero">
                                        
                                            
                                            <b-col md="0">
                                                <b-form-input placeholder="3 to 100 characters" v-model="fm.name" />
                                            </b-col>
                                        
                                    </b-col>
                                    <b-col md="1" class="text-center mobile_pdng_lft_rgt_zero mobile_md_one md_one_desktop">
                                        <b-button variant="danger" @click="removeRow(index);" class="">-</b-button>
                                    </b-col>
                                </b-col>

                                <b-row class="mt-1">

                                    <b-col md="11" class="text-center">
                                        <hr>
                                        <b-button class="mt-1 under_construct_mobile_add_more_btn" variant="primary" @click="addRow">Add More</b-button>
                                    </b-col>
                                </b-row>
                                            
                                <b-row>
                                    <b-col>
                                        <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" type = "submit" variant = "warning" class = "mr-1">
                                            Submit
                                        </b-button>

                                        <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="$router.go(-1)">
                                            Cancel
                                        </b-button>
                                    </b-col>
                                </b-row>

                            </b-col>
                        </b-row>

                    </b-form>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
    import {
        BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile,BBreadcrumb
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import { GET_API, POST_API } from "../../../store/actions.type"
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import vSelect from 'vue-select';

    export default {
        components: {
            BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, VueCropper, BFormRadio, BFormTimepicker, VueTimepicker, vSelect, BFormFile,BBreadcrumb
        },

        directives: {
            Ripple,
        },

        data() {
            return {        
                error_message:null,
                showDismissibleAlert:false,

                sites:[],
                form :{
                        name : [{name:''}],
                        site : [],
                }                
            }
        },

        methods : {
            formSubmit(){

                return this.$store.dispatch(POST_API, {
                    data:{
                        items : this.form,
                    },
                    api : '/api/add-daily-checklist-loc'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message        = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;

                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;

                        this.successAlert().then((result) => {
                            this.$router.go(-1);
                        });
                    }
                });
            },

            allSites(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        role:this.$store.getters.currentUser.role,
                        //om_sites:this.$store.getters.currentUser.om_sites,
                    },
                    api: '/api/all-sites'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        
                        this.sites = data;
                        return this.sites;
                    }
                });
            },
            addRow(){
                this.form.name.push({
                    name : ''
                })
            },

            removeRow(index) {
                
                this.form.name.splice(index, 1);
                
                if (this.form.name.length ==0) {
                    this.form.name.push({
                        name : ''
                    })
                }
                
            },
            breadCrumb(){
              var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
              },{
                to:{name:'custom-forms'},
                text: 'Custom Forms',
              },{
                to:{name:'daily-checklist'},
                text:'Daily Supervisor Checklist',
                
              },{
                to:{name:'daily-checklist-locations'},
                text:'Locations',           
              },{
                to:null,
                text:'Add Location',
                active:true
              }];
              return item;
            }
        },
        mounted(){
            this.allSites();
        }
    }
</script>
